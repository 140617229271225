import React,{useState,useEffect} from 'react'
import {Paper,Stepper,Step,StepLabel,Typography,CircularProgress,Divider,Button} from  '@material-ui/core'
import useStyles from './styles';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import { commerce } from '../../lib/commerce';
import {Link} from 'react-router-dom'
const steps=['shipping address', 'Payment details'];

const Checkout = ({cart,order,onCaptureCheckout,error}) => {
    const [activeStep,setActiveStep]=useState(0);
    const [checkoutToken,setCheckoutToken]=useState(null);
    const [shippingData,setShippingData]=useState({});
    const [data,setData]=useState();
    const classes=useStyles();

    useEffect(() => {
       const generateToken = async () => {
        try{
            const token = await commerce.checkout.generateToken(cart.id,{type:'cart'});
            console.log(token);
            setCheckoutToken(token);
         } catch(error){

            }
        }       
        generateToken();
    }, [cart])


    const nextStep =()=>setActiveStep((prevActiveStep)=>prevActiveStep + 1);
    const backStep =()=>setActiveStep((prevActiveStep)=>prevActiveStep - 1);

    const next =(data)=>{
        setShippingData(data);
        nextStep();
    }

   
    const handleCallback = (childData) =>{
      console.log(childData);
      setData(childData);
       }
useEffect(() => {
 handleCallback();
},[])
console.log(data);

    let Confirmation = () => (error ? (
        <>
          <div>
            <Typography variant="h5">Thank you for your purchase, {data.customer.firstname} {data.customer.lastname}!</Typography>
            <Divider className={classes.divider} />
            <Typography variant="subtitle2">Order ref: {data.payment.stripe.payment_method_id}</Typography>
          </div>
          <br />
          <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
        </>
      ) : (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      ));

     

      // if (error) { 
      //   Confirmation = () => (
      //     <>
      //       <Typography variant="h5">Error: {error}</Typography>
      //       <br />
      //       <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
      //     </>
      //   );
      // }

    const Form = () => activeStep ===0
    ? <AddressForm next={next} checkoutToken={checkoutToken}/>
    : <PaymentForm shippingData={shippingData} nextStep={nextStep} backStep={backStep} onCaptureCheckout={onCaptureCheckout} handleCallback={handleCallback} checkoutToken={checkoutToken}/>

  return (
    <>
      <div className={classes.toolbar}>
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Typography variant='h4' align='center'>Checkout</Typography>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((step)=>(
                        <Step key={step}>
                            <StepLabel>{step}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep ===steps.length ? <Confirmation/> : checkoutToken && <Form/>}
            </Paper>
        </main>
      </div>
    </>
  )
}

export default Checkout
