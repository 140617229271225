import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Typography,
} from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import logo from "../../assets/commerce.png";
import { Link, useLocation } from "react-router-dom";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";

const Navbar = ({ cart, products, refreshCart }) => {
  const navigate = useNavigate();

  const productsname = products.map((name) => name.name);
  console.log(productsname);
  // console.log(productsname.filter((country) =>
  // country.name.match("Lamp")));

  console.log(cart.total_items);
  const classes = useStyles();
  const location = useLocation();

  const logout = () => {
    refreshCart();
    localStorage.removeItem("Eldata");
    navigate("/signin");
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar>
          <Typography
            component={Link}
            to="/"
            variant="h6"
            className={classes.title}
            color="inherit"
          >
            <img
              src={logo}
              alt="Commerce.js"
              height="25px"
              className={classes.image}
            />
            Shopping Stop
          </Typography>

          {/* <input style={{width: "40rem",
    margin: "10px",
    height: "2rem",
    borderColor: "orange"}}
   type="text"
   placeholder="Search here"
   /> <button style={{position: "relative",
    zIndex: "1",
    left: "-0.6rem",
    height: "2.0rem",
    borderColor: "orange",
    backgroundColor: "orange"}} type="submit"><i class="fa fa-search"></i></button> */}

          
          <div className={classes.grow} />
          {localStorage.getItem("Eldata") === null 
          ? ""
          : (
            <>
             
              <button
                style={{
                  position: "relative",
                  borderColor: "orange",
                  backgroundColor: "orange",
                  fontSize: "small",
                  fontWeight: "600",
                }}
                onClick={logout}
              >
                Log Out
              </button>
            </>
          )}

          {location.pathname === "/" && (
            <div className={classes.button}>
              <IconButton
                component={Link}
                to="/cart"
                aria-label="Show cart items"
                color="inherit"
              >
                <Badge badgeContent={cart.total_items} color="secondary">
                  <ShoppingCart />
                </Badge>
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>     
    </>
  );
};

export default Navbar;
