import React,{useState,useEffect} from 'react'
import {InputLabel,Select,MenuItem,Button,Grid,Typography} from '@material-ui/core';
import {useForm, FormProvider} from 'react-hook-form';
import FormInput from './FormInput';
import {Link} from 'react-router-dom';

import { commerce } from '../../lib/commerce';

const AddressForm = ({checkoutToken,next}) => {
   

    const {register,handleSubmit}=useForm();


    const onSubmit = (data, e) =>
    { next(data); 
console.log(data);
    }

  return (
    <>
      <Typography variant='h6' gutterBottom>Shipping Address</Typography>
      <FormProvider >
        <form onSubmit={handleSubmit(onSubmit)} >
        <Grid container spacing={3} style={{flexDirection:'column', padding:'20px'}} >
            <label>First name : </label>
            <input required {...register("firstName")} style={{ marginTop:'20px',padding:'10px',borderRadius: "10px"}}  />
            <label>Last name : </label>
            <input required {...register("lastName")}  style={{ marginTop:'20px',padding:'10px',borderRadius: "10px"}} />
            <label>Address : </label>
            <input required  {...register("address")}  style={{ marginTop:'20px',padding:'10px',borderRadius: "10px"}}/>
            <label>Email : </label>
            <input required {...register("email")}  style={{ marginTop:'20px',padding:'10px',borderRadius: "10px"}} />
            <label>City : </label>
            <input required {...register("city")}   style={{ marginTop:'20px',padding:'10px',borderRadius: "10px"}} />
            <label>Zip postal code : </label>
            <input required {...register("zip")}   style={{ marginTop:'20px',padding:'10px',borderRadius: "10px"}} />
                {/* <Grid item xs={12} sm={6}>
                    <InputLabel>Shipping Country</InputLabel>
                    <Select value={} fullWidth onChange={}>
                        <MenuItem key={} value={}>
                            Select Me
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel>Shipping Subdivision</InputLabel>
                    <Select value={} fullWidth onChange={}>
                        <MenuItem key={} value={}>
                            Select Me
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel>Shipping Options</InputLabel>
                    <Select value={} fullWidth onChange={}>
                        <MenuItem key={} value={}>
                            Select Me
                        </MenuItem>
                    </Select>
                </Grid> */}
        </Grid>
        <br></br>
        <div style={{display:'flex',justifyContent:'space-between'}}>
            <Button component={Link} to='/cart' variant='outlined'>Back to Cart</Button>
            <Button variant='contained' type='submit' color='primary'>Next</Button>
        </div>
        </form>
      </FormProvider>
    </>
  )
}

export default AddressForm
