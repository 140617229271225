import React,{useState, useEffect} from 'react'
//import Products from './components/Products/Products'
//import Navbar from './components/Navbar/Navbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {commerce} from './lib/commerce';
import {Products,Navbar,Cart,Checkout} from './components';
import Sign_in from './components/SignIn_SignUp/Sign_in';
import "bootstrap/dist/css/bootstrap.min.css"

const App = () => {
const [products,setProducts]=useState([]);
const [cart, setCart] = useState({});
const [order,setOrder]= useState({});
const [errorMessage,setErrorMessage] = useState();

const fetchProducts = async ()=>{
  const {data} =await commerce.products.list();
  setProducts(data);
}

const fetchCart = async ()=>{
  setCart(await commerce.cart.retrieve());
}

const handleAddToCart = async (productId,quantity) =>{
  const item =await commerce.cart.add(productId,quantity);
  setCart(item);
}

const handleUpdateCartQty = async (lineItemId, quantity) => {
  const response = await commerce.cart.update(lineItemId, { quantity });

  setCart(response);
};

const handleRemoveFromCart = async (lineItemId) => {
  const response = await commerce.cart.remove(lineItemId);
  setCart(response);
};

const handleEmptyCart = async () => {
  const response = await commerce.cart.empty();
  setCart(response);
};

const refreshCart = async ()=>{
  const newCart = await commerce.cart.refresh();
  setCart(newCart);
}

const handleCaptureCheckout= async (checkoutTokenId,newOrder)=>{
  try{
    const incomingOrder = await commerce.checkout.capture(checkoutTokenId,newOrder);
    setOrder(incomingOrder);
    console.log(incomingOrder);
  }
   catch (error){
    setErrorMessage(error.data.error.message);
    refreshCart();
   }
}
console.log(order);

useEffect(()=>{
  fetchProducts();
  fetchCart();
},[]);
console.log(cart.total_items);
 
  return (
    <Router>   
      <div>
      <Navbar products={products}  refreshCart={refreshCart} cart={cart}  />
      <Routes>
        <Route exact path="/" element={<Products products={products} onAddToCart={handleAddToCart}  />}>
       </Route>          
        <Route exact path="/cart" element={<Cart cart={cart} onUpdateCartQty={handleUpdateCartQty} onRemoveFromCart={handleRemoveFromCart} onEmptyCart={handleEmptyCart} />}>
        </Route> 
        <Route exact path="/checkout" element={<Checkout order={order} onCaptureCheckout={handleCaptureCheckout} error={errorMessage} cart={cart}/> }>
        </Route> 
        <Route exact path="/signin" element={<Sign_in></Sign_in>}>
       </Route>          
      </Routes>   
      </div>
  </Router>


  )
}

export default App

