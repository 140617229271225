import React, { useState, useEffect } from "react";
import "../SignIn_SignUp/Style.css";
import {useNavigate} from 'react-router-dom'

export default function (props) {
  let [authMode, setAuthMode] = useState("signin");
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [pwd, setPwd] = useState();
  const navigate=useNavigate();
  const handlesubmit2 = (e) => {
    e.preventDefault();
    
     const info=JSON.parse(localStorage.getItem("Erdata"));
     if(info.find((item)=>item.email===email && item.pwd===pwd)){
      alert('Login successful');
      const mytodo1 = {
        email: email,
        pwd: pwd,
      };    
      
      localStorage.setItem('Eldata',JSON.stringify([mytodo1]));
      navigate('/');
   
     }else{
      alert('wrong detailed');
     }
     console.log(info.length);     
  };
  

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };
  let initTodo;
  if(localStorage.getItem("Erdata")===null){
    initTodo=[];
  }else{
    initTodo=JSON.parse(localStorage.getItem("Erdata"));
  } 
  const save = () => {
    let sno;
        if (data.length === 0) {
          sno = 0;
        } else {
          sno = data[data.length - 1].sno + 1;
        }
    const mytodo = {
      sno:sno,
      name: name,
      email: email,
      pwd: pwd,
    };    
    setData([...data,mytodo]);
    console.log(mytodo);
    console.log(data);
  };
  const [data, setData] = useState(initTodo);
 
  const handlesubmit1 = (e) => {
    e.preventDefault();
    save();
    setName("");
    setEmail("");
    setPwd("");    
    alert('Registration successful');
    navigate('/signin');
  };

  useEffect(() => {
    localStorage.setItem('Erdata',JSON.stringify(data));
  }, [data]);

  

  if (authMode === "signin") {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={handlesubmit2}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="text-center">
              Not registered yet?{" "}
              <span className="link-primary" onClick={changeAuthMode}>
                Sign Up
              </span>
            </div>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                required
                value={email}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                onChange={(e) => setPwd(e.target.value)}
                required
                value={pwd}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            {/* <p className="text-center mt-2">
              Forgot <a href="#">password?</a>
            </p> */}
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handlesubmit1}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="text-center">
            Already registered?{" "}
            <span className="link-primary" onClick={changeAuthMode}>
              Sign In
            </span>
          </div>
          <div className="form-group mt-3">
            <label>Full Name</label>
            <input
              type="text"
              required
              value={name}
              className="form-control mt-1"
              placeholder="e.g Jane Doe"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              required
              value={email}
              className="form-control mt-1"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              required
              value={pwd}
              className="form-control mt-1"
              placeholder="Password"
              onChange={(e) => setPwd(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          {/* <p className="text-center mt-2">
            Forgot <a href="#">password?</a>
          </p> */}
        </div>
      </form>
    </div>
  );
}
