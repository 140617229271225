import React from 'react'
import {Card, CardMedia,CardContent,CardActions,Typography,IconButton} from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';
import {Link,useNavigate} from 'react-router-dom'
import useStyles from './styles';

const Product = ({product,onAddToCart}) => {
  const classes = useStyles();
  const navigate=useNavigate();
  
   
  const saveCart=()=>{
    
    if(localStorage.getItem("Eldata")===null){      
      navigate("/signin");
    }
    else{
      console.log(localStorage.getItem("Eldata"));
      onAddToCart(product.id, 1);
    }
  }




  return (
    <Card className={classes.root}>
        <CardMedia className={classes.media} image={product.image.url} title={product.name}/>
       <CardContent>
        <div className={classes.cardContent}>
        <Typography variant='h5' gutterBottom>
                {product.name}
           </Typography>
            <Typography variant='h5'>
                {product.price.formatted_with_symbol}
           </Typography>
        </div>
        <Typography dangerouslySetInnerHTML={{__html:product.description}} variant='body2' color='textSecondary'>
            
        </Typography>
       </CardContent>
       <CardActions disableSpacing className={classes.CardActions}>
            <IconButton aria-label='Add to Cart' onClick={saveCart}>
                <AddShoppingCart/>
            </IconButton>
       </CardActions>
    </Card>
  )

}

export default Product
